
import React, {   useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import {  AddCoupon, UpdateCoupon } from '../../apollo';
import { Button, Box, Typography, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions,Alert, Snackbar, Autocomplete, Paper } from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';


const CREATE_COUPON = gql`
  ${AddCoupon}
`;
const EDIT_COUPON = gql`
  ${UpdateCoupon}
`;


const CustomPaperComponent = (props) => (
    <Paper {...props} style={{ background: 'white', color: 'black' }} />
  );
function AddCoupons(props) {
  const { onClose ,vendor } = props;
  const formRef = useRef();
  const mutation = props.vendor ? EDIT_COUPON : CREATE_COUPON;
  let [error, errorSetter] = useState('');
  // const [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const [openSnackk, setopenSnackk] =useState(false);
  const { t } = props;
  const [endDateError, setEndDateError] = useState('');
  const userType =  JSON.parse(localStorage.getItem('user-native'))
  const formatToYYYYMMDD = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];  // Convert to "YYYY-MM-DD" format
  };
  const [formData, setFormData] = useState({
    name: props.vendor ? props.vendor.name : '',
    type: props.vendor ? props.vendor.type : '',
    code: props.vendor ? props.vendor.code : '',
    start: props.vendor ? props.vendor.start : '',
    end: props.vendor ? props.vendor.end : '',
    applyAmount: props.vendor ? props.vendor.applyAmount : '',
    description: props.vendor ? props.vendor.description : '',
    orderAmount: props.vendor ? props.vendor.orderAmount : ''
  });
  

  const [StartDate, setStartDate] = useState(formatToYYYYMMDD(formData.start));
  const [EndDate, setEndDate] = useState(formatToYYYYMMDD(formData.end));
const formatDateToISO = (dateString) => {
    const date = new Date(dateString);
    const isoString = date.toISOString(); // This will give you the date in "2024-09-04T00:00:00.000Z" format
    return isoString.replace('Z', '+00:00'); // Replacing 'Z' with '+00:00' to match your required format
  };
  const typeOptions = [
    { label: 'SINGLE USE', value: 0 },
    { label: 'FIRST USE', value: 1 },
    { label: 'EVENT', value: 2 },
    { label: 'ORDER AMOUNT', value: 3 },
  ];
  
  const [formErrors, setFormErrors] = useState({});
  const handleEndDateChange = (value) => {
    const newEndDate = value.target.value;
    if (newEndDate < StartDate) {
      setEndDateError('End date cannot be less than start date.');
    } else {
      setEndDateError('');
      setEndDate(newEndDate);
      const formattedEndDate = formatDateToISO(newEndDate);
      setFormData({ ...formData, end: newEndDate });
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

   
      setFormData({ ...formData, [name]: value });

    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };

  const handleTypeChange = (event, newValue) => {
    if (newValue) {
      setFormData({ ...formData, type: newValue.value });
    }
  };
  // const validateFormData = () => {
  //   let errors = {};
  //   // // if(!formData._id) error._id= "Sub Category ID is required";
  //   if (!formData.name) errors.name = 'Store Name is required';
  //   if (! formData.address) errors.address = 'Address is required';
  //   if (!formData.city) errors.city = 'City Name is required';
  //   if (!formData.Code) errors.Code = 'Code  is required';
  //   // if (props.vendor && !formData.location.longitude) errors.longitude = 'Longitude is required';
  //   // if (props.vendor && !formData.location.latitude) errors.latitude = 'Latitude is required';
  //   // if(!formData.description) error.description= "Discription is required";
  //   setFormErrors(errors);
  //   return Object.keys(errors).length === 0;
  // };
const onUpdateCompleted = (data) => {
  const message = t('Bounds Updated Successfully');
  errorSetter('');
  setTimeout( ()=> {successSetter(message);
  setopenSnackk(true);

  props.refetch();
  setTimeout(hideAlert, 1500);}, 1000)
 
};

const onCompleted = (data) => {
 
  if (!props.vendor) clearFields();
  const message = props.vendor ? t('Coupon Updated Successfully') : t('Coupon Added Successfully');
  errorSetter('');
  successSetter(message);
   setopenSnackk(true)
   props.refetch()
  setTimeout(hideAlert, 3000);
};

const onError = ({ graphQLErrors, networkError }) => {
  successSetter('');
  if (graphQLErrors) 
  {
    errorSetter('Something went wrong!');
  }
 
  else if (networkError) errorSetter(networkError.result.errors[0].message);
  else errorSetter('Something went wrong!');
  setTimeout(hideAlert, 3000);
  setopenSnackk(true)
};

 
  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    // refetchQueries: [{ query: GET_CENTRAL_STORE }],
    onError,
    onCompleted,
  });

  const clearFields = () => {
    formRef.current.reset();
    setFormErrors({});
  
 setFormData (  {
    name: props.vendor ? props.vendor.name : '',
    type: props.vendor ? props.vendor.type : '',
    city: props.vendor ? props.vendor.city : '',
    start: props.vendor ? props.vendor.start : '',
    end: props.vendor ? props.vendor.end : '',
    applyAmount: props.vendor ? props.vendor.applyAmount : '',
    orderAmount: props.vendor ? props.vendor.orderAmount : '',
    })
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };




  const onSubmit=(e)=>{
    e.preventDefault();
    // if (!validateFormData()) {
    //   // setOpenSnack(true);
    //   return;
    // }
  
    if(true){
!props.vendor ?      mutate({
        variables:{
            "couponInput": {
              "name":formData.name, 
              "description" : formData.description,
                "type": parseInt(formData.type),
              "code": formData.code,
              "start": formData.start,
              "end": formData.end,
              "applyAmount": parseFloat(formData.applyAmount),
              "orderAmount":parseFloat(formData.orderAmount)
            }
          }
        
      }):
      mutate({
        variables:{
            "code": formData.code,
            "start": formData.start,
            "end": formData.end,
            "applyAmount": parseFloat(formData.applyAmount)
          }
      })
      ;
      // Close the modal after 3 seconds by calling the parent's onClose callback
      setTimeout(() => {
        if (typeof props.onClose === 'function') {
          props.onClose(); // Close the modal
        }
      }, 2000);
    }
  
  
  }
  return (
    <Dialog open={true}   onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box className={props.vendor ? classes.heading : classes.heading}>
          <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
            {props.vendor ? t('Edit Coupon ') : t('Add Coupon ')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
      <form ref={formRef}
        onSubmit={onSubmit}
        >
          <Grid container spacing={2}>
            {!props.editModal ?
             <>
             <Grid item xs={12} sm={6}>
              <TextField name="name" label="Coupon Name" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                 error={Boolean(formErrors.name)}
                 helperText={formErrors.name}
                 />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={typeOptions}
                getOptionLabel={(option) => option.label}
                value={typeOptions.find(option => option.value === formData.type) || null}
                onChange={handleTypeChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type"
                    variant="outlined"
                    fullWidth
                    inputProps={{ ...params.inputProps, style: inputTextStyle }}
                    error={Boolean(formErrors.type)}
                    helperText={formErrors.type}
                  
                  />
                  
                )}
                PaperComponent={CustomPaperComponent}
              />
            </Grid>
            
            

            <Grid item xs={12} sm={6}>
                <TextField
                  label="Select Start Date"
                  type="date"
                  value={StartDate}

                  onChange={(value) => {
                    const formattedEndDate = formatDateToISO(value.target.value);
                    setStartDate(value.target.value);
                    setFormData({ ...formData, start: formattedEndDate });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: { xs: '100%', sm: '50%', md: '100%', lg: '100%' },
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Select End Date"
                  type="date"
                  value={EndDate}
                  onChange={handleEndDateChange}
                  error={!!endDateError}
                  helperText={endDateError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: { xs: '100%', sm: '50%', md: '100%', lg: '100%' },
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <TextField name="code"label="Code" variant="outlined" fullWidth  onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
               error={Boolean(formErrors.code)}
               helperText={formErrors.code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField name="orderAmount" label="Order Amount" variant="outlined" fullWidth  onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
               error={Boolean(formErrors.orderAmount)}
               helperText={formErrors.orderAmount}
               />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField name="applyAmount" label="Apply Amount" variant="outlined" fullWidth  onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
               error={Boolean(formErrors.applyAmount)}
               helperText={formErrors.applyAmount}
               />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField name="description" label="Description" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
                 error={Boolean(formErrors.description)}
                 helperText={formErrors.description}
                 />
            </Grid>
           </>
              :
              ( // Conditionally render the second form fields based on editModal prop
              <>
           <Grid item xs={12} sm={6}>
              <TextField  disabledname="name" label="Coupon Name" variant="outlined" fullWidth value={formData.name} onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
               error={Boolean(formErrors.name)}
               helperText={formErrors.name}
               />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={typeOptions}
                getOptionLabel={(option) => option.label}
                value={typeOptions.find(option => option.value === formData.type) || null}
                onChange={handleTypeChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type"
                    variant="outlined"
                    fullWidth
                    inputProps={{ ...params.inputProps, style: inputTextStyle }}
                    error={Boolean(formErrors.type)}
                    helperText={formErrors.type}
                  
                  />
                )}
                PaperComponent={CustomPaperComponent}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
                <TextField
                  label="Select Start Date"
                  type="date"
                  value={StartDate}

                  onChange={(value) => {
                    setStartDate(value.target.value);
                    setFormData({ ...formData, start: value.target.value });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: { xs: '100%', sm: '50%', md: '100%', lg: '100%' },
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Select End Date"
                  type="date"
                  value={EndDate}
                  onChange={handleEndDateChange}
                  error={!!endDateError}
                  helperText={endDateError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: { xs: '100%', sm: '50%', md: '100%', lg: '100%' },
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <TextField name="code" label="Code" 
               error={Boolean(formErrors.code)}
               helperText={formErrors.code}
               variant="outlined" fullWidth value={formData.code} onChange={handleInputChange} inputProps={{ style: inputTextStyle }}/>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField name="applyAmount" label="Apply Amount"
               error={Boolean(formErrors.applyAmount)}
                helperText={formErrors.applyAmount}
                
                variant="outlined" fullWidth value={formData.applyAmount } onChange={handleInputChange} inputProps={{ style: inputTextStyle }}/>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField name="description" label="Description"
               error={Boolean(formErrors.description)}
                helperText={formErrors.description}
                
                variant="outlined" fullWidth value={formData.description } onChange={handleInputChange} inputProps={{ style: inputTextStyle }}/>
            </Grid>
  

           </>
           ) 
            }
          </Grid>
          <DialogActions>
     
     <Button
       className={globalClasses.dashbordBtn}
       disabled={mutateLoading}
         type='submit'
      
     >
       {props.vendor ? t('Update') : t('Add')}
     </Button>
     <Button
       className={globalClasses.modalCancleBtn}
       onClick={() => {
         onClose();
       }}
     >
       Cancel
     </Button>
   </DialogActions>
   </form>
      </DialogContent>
    
    
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}
export default withTranslation()(AddCoupons);
