
import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery, useApolloClient } from '@apollo/client';
import { Container, Grid, Box, TextField, Button, MenuItem, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import DataTable from 'react-data-table-component';
import CustomLoader from '../components/Loader/CustomLoader';
import { customStyles } from '../utils/tableStyleForSore';
import useGlobalStyles from '../utils/globalStyles'
import { GetOrderedItemsByTimeSlot ,GetLocalStoreCustomerDeliverySlot} from '../apollo';

const GET_LOCAL_STORE_CUSTOMER_DELIVERY_SLOT = gql`
  ${GetLocalStoreCustomerDeliverySlot}
`
const GET_ORDERED_ITEMS_BY_TIME_SLOT = gql`
  ${GetOrderedItemsByTimeSlot}
`



// Status mapping object
const statuses = {
  '-1': 'All',
  1: 'Confirmed',
  2: 'Packing',
  3: 'Order billing',
  4: 'Ready for delivery',
  5: 'Rider Assigned',
  6: 'Out for delivery',
  7: 'Delivered',
  8: 'Cancelled',
};


const OrdersbyTimeSlot = () => {
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [orderDate, setOrderDate] = useState('');
  const [orderStatus, setOrderStatus] = useState(-1);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [storeName, setStoreName] = useState('');
  const globalClasses = useGlobalStyles()
  const [pagination, setPagination] = useState({ skip: 0, limit: 10 });

  const client = useApolloClient();

  // Fetch time slots on component load
  useEffect(() => {
    client
      .query({ query: GET_LOCAL_STORE_CUSTOMER_DELIVERY_SLOT })
      .then(function (response) {
        var fetchedSlots =
          (response &&
            response.data &&
            response.data.getLocalStoreCustomerDeliverySlot &&
            response.data.getLocalStoreCustomerDeliverySlot.timeSlot) ||
          [];
        setTimeSlots(fetchedSlots);
      })
      .catch(function (error) {
        console.error('Error fetching time slots:', error);
      });
  }, [client]);
  

  // Lazy query for fetching ordered items by time slot
  const [fetchOrderedItems, { loading: dataLoading }] = useLazyQuery(
    GET_ORDERED_ITEMS_BY_TIME_SLOT,
    {
      onCompleted: (data) => {
        setTableData(data.getOrderedItemsByTimeSlot.timeSlotItems || []);
        setTotalCount(data.getOrderedItemsByTimeSlot.totalCount || 0);
        setStoreName(data.getOrderedItemsByTimeSlot.storeName || '');
      },
      onError: (error) => console.error('Error fetching ordered items:', error),
    }
  );

  // Fetch orders based on selected filters
  const handleFetchOrders = () => {
    if (!selectedTimeSlot) {
      alert('Please select a time slot.');
      return;
    }

    fetchOrderedItems({
      variables: {
        timeSlotId: selectedTimeSlot,
        orderDate,
        orderStatus: parseInt(orderStatus, 10),
        skip: pagination.skip,
        limit: pagination.limit,
      },
    });
  };

  // Table columns
  const columns = [
    { name: 'Product ID', selector: 'productId', sortable: true },
    { name: 'Product Name', selector: 'productName', sortable: true },
    { name: 'Total Quantity', selector: 'totalQty', sortable: true },
    { name: 'Total Orders', selector: 'totalOrders', sortable: true },
    { name: 'Category', selector: 'category', sortable: true },
  ];

  return (
    <Container className={globalClasses.flex}>
      <Box sx={{ mb: 3, mt: 3 }}>
        
        <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <TextField
            select
            label="Time Slot"
            value={selectedTimeSlot}
            onChange={(e) => setSelectedTimeSlot(e.target.value)}
            fullWidth
            sx={{
              input: { color: 'black' },
              '.MuiSelect-select': { color: 'black' },
            }}
          >
            {timeSlots.length > 0 ? (
              timeSlots.map((slot) => (
                <MenuItem
                  key={slot._id}
                  value={slot._id}
                  sx={{ color: 'black' }} // Ensure text color is black
                >
                  {slot.start} - {slot.end}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled sx={{ color: 'black' }}>
                No Time Slots Available
              </MenuItem>
            )}
          </TextField>

          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Select Date"
              type="date"
              value={orderDate}
              onChange={(e) => setOrderDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              sx={{
                input: { color: 'black' },
              }}
            />
          </Grid>
          <Grid item xs={3}>
          <TextField
            select
            label="Order Status"
            value={orderStatus}
            onChange={(e) => setOrderStatus(e.target.value)}
            fullWidth
            sx={{
              input: { color: 'black' },
              '.MuiSelect-select': { color: 'black' },
            }}
          >
            {Object.entries(statuses).map(([key, value]) => (
              <MenuItem key={key} value={key} sx={{ color: 'black' }}>
                {value}
              </MenuItem>
            ))}
          </TextField>

          </Grid>
        </Grid>
        <Box mt={3}>
          <Button onClick={handleFetchOrders} variant="contained" color="primary">
            <Icon icon="ic:baseline-search" style={{ marginRight: '8px' }} /> Fetch Orders
          </Button>
        </Box>
      </Box>

      {dataLoading ? (
        <CustomLoader />
      ) : (
        <>
          <Box mb={3}>
            <Typography variant="h6" sx={{ color: 'black' }}>
              Store Name: {storeName || 'N/A'}
            </Typography>
            <Typography variant="h6" sx={{ color: 'black' }}>
              Total Items: {totalCount}
            </Typography>
          </Box>
          <DataTable
            columns={columns}
            data={tableData}
            pagination
            customStyles={customStyles}
          />
        </>
      )}
    </Container>
  );
};

export default OrdersbyTimeSlot;
