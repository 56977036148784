import React, { useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  TextField,
  DialogActions,
  Alert,
  Snackbar,
  Container,
  Box,
  Typography,
} from '@mui/material';
import useGlobalStyles from '../utils/globalStyles';

const UPGRADE_CUSTOMER_TO_PREMIUM = gql`
  mutation UpgradeCustomerToPremiumByPhoneNumbers($upgradeInput: UpgradeInput!) {
    upgradeCustomerToPremiumByPhoneNumbers(upgradeInput: $upgradeInput)
  }
`;

function UpgradeToPremium(props) {
  const globalClasses = useGlobalStyles();
  const { t } = props;
  const formRef = useRef();
  const [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const [openSnackk, setOpenSnackk] = useState(false);
  const [formData, setFormData] = useState({
    phoneNumbers: '',
    expiryDate: '',
  });

  const handleFormInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateInputs = () => {
    const { expiryDate, phoneNumbers } = formData;
    const currentDate = new Date().toISOString().split('T')[0];

    if (expiryDate && expiryDate < currentDate) {
      errorSetter(t('Expiry date cannot be in the past.'));
      setOpenSnackk(true);
      return false;
    }

    const phoneArray = phoneNumbers.split(',').map((num) => num.trim());
    const phoneRegex = /^\+91\d{10}$/;

    for (let phone of phoneArray) {
      if (!phoneRegex.test(phone)) {
        errorSetter(t('One or more phone numbers are invalid. Phone numbers must start with +91 and be 10 digits long.'));
        setOpenSnackk(true);
        return false;
      }
    }

    return true;
  };

  const onCompleted = () => {
    const message = t('Customers upgraded to premium successfully');
    errorSetter('');
    successSetter(message);
    setTimeout(hideAlert, 3000);
    setOpenSnackk(true);
    setFormData({ phoneNumbers: '', expiryDate: '' });
  };

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors && graphQLErrors.length > 0) {
      errorSetter(graphQLErrors[0].message || 'An error occurred');
    } else if (
      networkError &&
      networkError.result &&
      networkError.result.errors &&
      networkError.result.errors.length > 0
    ) {
      errorSetter(networkError.result.errors[0].message || 'A network error occurred');
    } else {
      errorSetter('Something went wrong!');
    }
    setOpenSnackk(true);
  };

  const [mutate, { loading: mutateLoading }] = useMutation(UPGRADE_CUSTOMER_TO_PREMIUM, {
    onError,
    onCompleted,
  });

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setOpenSnackk(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    mutate({
      variables: {
        upgradeInput: {
          expiryDate: formData.expiryDate || null,
          phoneNumbers: formData.phoneNumbers
            .split(',')
            .map((num) => num.trim())
            .filter((num) => num.length > 0) || null,
        },
      },
    });
  };

  return (
    <>
      <Container className={globalClasses.flexRow}>
        <Grid
          sx={{
            backgroundColor: 'white',
            py: 6,
            px: 6,
            margin: '1.5rem',
            borderRadius: '10px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <form ref={formRef} onSubmit={onSubmit}>
            <Typography variant="h6" style={{ textAlign: 'left' }} sx={{ marginBottom: '1.5rem' }}>
              {t('Upgrade Customers to Premium')}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container alignItems="top">
                  <Grid item xs={2}>
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      {t('Expiry Date')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      name="expiryDate"
                      variant="outlined"
                      fullWidth
                      type="date"
                      value={formData.expiryDate}
                      onChange={handleFormInputChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        style: { color: 'black' },
                        min: new Date().toISOString().split('T')[0],
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="top">
                  <Grid item xs={2}>
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      {t('Enter Customer Phone Numbers')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      name="phoneNumbers"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={12}
                      value={formData.phoneNumbers}
                      onChange={handleFormInputChange}
                      placeholder="+919731xxxxxx,+919886xxxxxxx"
                      inputProps={{ style: { color: 'black' } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <DialogActions>
              <Button
                type="submit"
                className={globalClasses.dashbordBtn}
                disabled={mutateLoading}
                sx={{
                  backgroundColor: '#3f51b5',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#303f9f',
                  },
                }}
              >
                {t('Upgrade')}
              </Button>
            </DialogActions>
          </form>
        </Grid>
      </Container>
      <Box mt={2}>
        {success && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={hideAlert}
          >
            <Alert onClose={hideAlert} severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={hideAlert}
          >
            <Alert onClose={hideAlert} severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </>
  );
}

export default withTranslation()(UpgradeToPremium);
