import React, { useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import {
  Button, Box, Typography, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar,
} from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';
import { CancelOrder, createcsadmin, modifyorder, ProcessOrderRefund } from '../../apollo';
import DataTable from 'react-data-table-component';

const CREATE_LOCAL_STORE_ADMIN = gql`${createcsadmin}`;
const EDIT__LOCAL_STORE_ADMIN = gql`${modifyorder}`;
const EDIT_ORDER_REFUND = gql`${ProcessOrderRefund}`;
const CANCEL_ORDER = gql`${CancelOrder}`;

function EditOrderByWorker(props) {
  const { onClose, refetch, payMethod, sellingPrice } = props;
  console.log(props.vendor ,"data from parent ")
  const formRef = useRef();
  // const [error, setError] = useState('');
  // const [success, setSuccess] = useState('');
  // const [openSnack, setOpenSnack] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const userType = JSON.parse(localStorage.getItem('user-native'));
  const orderId = localStorage.getItem('orderId');

  const inputTextStyle = { color: 'black' };
  const [formData, setFormData] = useState({
    id: userType._id,
    name: props.vendor ? props.vendor.name : '',
    quantity: props.vendor ? props.vendor.quantity : '',
    instruction: '',
    skuId: props.vendor ? props.vendor.skuId : '',
    productId: props.vendor ? props.vendor.productId : '',
  });

  const [modifiedData, setModifiedData] = useState(props.vendor);
  const [comment, setComment] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
console.log(modifiedData ,"modifiedData")
  const [modifyOrder] = useMutation(EDIT__LOCAL_STORE_ADMIN, {
    onCompleted: (data) => {
      setSuccess("Modified Successfully");
      setOpenSnack(true);
      refetch();
      setTimeout(() => onClose(), 2000);
    },
    onError: (error) => {
      setError(error.message);
      setOpenSnack(true);
    },
  });

  const handleQuantityChange = (index, value) => {
    if (value < 0 || value > props.vendor[index].quantity) return;
    const updatedData = [...modifiedData];
    updatedData[index].quantity = value;
    setModifiedData(updatedData);
  };

  const handleSubmit = () => {
    if (!comment) {
      setError('Please enter a comment before submitting.');
      setOpenSnack(true);
      return;
    }
    
    const payload = {
      replaceOrder: {
        id: localStorage.getItem('orderId'),
        itemInputs: modifiedData.map(item => ({
          productId: item.productId,
          skuId: item.skuId,
          quantity: parseInt(item.quantity),
        })),
        instructions: comment,
      },
    };
    
    modifyOrder({ variables: payload });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'quantity' && parseInt(value) > props.vendor.quantity) {
      setError('Please enter a quantity less than or equal to the current quantity.');
      setOpenSnack(true);
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const onCompleted = async (data) => {
    setError('');
    setSuccess('Quantity Updated Successfully');
    setOpenSnack(true);
    refetch();

    if (payMethod && payMethod === 1) {
      await processOrderRefund({
        variables: {
          orderId,
          refundAmount: sellingPrice,
          reason: formData.instruction,
          isOrderCancelled: parseInt(formData.quantity) === 0,
        },
      });
    }
  };

  const onError = (error) => {
    setSuccess('');
    const errorMessage =
    error &&  error.graphQLErrors[0] &&  error.graphQLErrors[0].message || 'Something went wrong!';
    if (errorMessage === 'All items removed!! Please Cancel the Order') {
      setShowCancelOrderModal(true);
    } else {
      setError(errorMessage);
      setOpenSnack(true);
    }
  };

  const [mutate] = useMutation(props.vendor ? EDIT__LOCAL_STORE_ADMIN : CREATE_LOCAL_STORE_ADMIN, {
    onCompleted,
    onError,
  });

  const [processOrderRefund] = useMutation(EDIT_ORDER_REFUND);
  const [cancelOrder] = useMutation(CANCEL_ORDER, {
    onCompleted: (data) => {
      console.log("i am comming here " ,data.cancelOrder? data.cancelOrder: "nothing")

      if (data.cancelOrder) {
        setError(''); // Clear previous errors
        setSuccess(data.cancelOrder.message); // Set success message
        setOpenSnack(true); // Trigger Snackbar display
        setShowCancelOrderModal(false);
        refetch();
        setTimeout(hideAlert, 3000);
        
      }
     
    },
    onError: (error) => {
      setError(error.message || 'Failed to cancel the order');
      setOpenSnack(true);
    },
  });
  console.log("i am comming here " ,success ,error ,openSnack)
  const hideAlert = () => {
    setError('');
    setSuccess('');
    setOpenSnack(false); 
    onClose();
  };
  const handleCancelOrder = () => {
    cancelOrder({ variables: { orderId } });
  };

  const handleCloseSnack = () => {
    setError('');
    setSuccess('');
    setOpenSnack(false);
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <>
      {/* <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box className={classes.heading}>
            <Typography className={classes.textWhite}>{props.t('Edit Order Details')}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="name"
                value={formData.name}
                label="Name"
                variant="outlined"
                fullWidth
                onChange={handleInputChange}
                inputProps={{ style: inputTextStyle }}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="quantity"
                value={formData.quantity}
                label="Quantity"
                variant="outlined"
                fullWidth
                inputProps={{ style: inputTextStyle }}
                onChange={handleInputChange}
                helperText="**Enter '0' to delete item from order"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="instruction"
                label="Comment"
                value={formData.instruction}
                variant="outlined"
                fullWidth
                inputProps={{ style: inputTextStyle }}
                onChange={handleInputChange}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={globalClasses.dashbordBtn}
            onClick={(e) => {
              e.preventDefault();
              if (!formData.instruction) {
                setError('Please Fill Comment');
                setOpenSnack(true);
                return;
              }
              mutate({
                variables: {
                  replaceOrder: {
                    id: orderId,
                    instructions: formData.instruction,
                    itemInputs: [
                      {
                        productId: formData.productId,
                        quantity: parseInt(formData.quantity),
                        skuId: formData.skuId,
                      },
                    ],
                  },
                },
              });
            }}
          >
            {props.vendor ? props.t('Update') : props.t('Save')}
          </Button>
          <Button className={globalClasses.modalCancleBtn} onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
        <Snackbar
  open={openSnack}
  autoHideDuration={6000}
  onClose={handleCloseSnack}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  {success ? (
    <Alert onClose={handleCloseSnack} severity="success" variant="filled">
      {success}
    </Alert>
  ) : (
    <Alert onClose={handleCloseSnack} severity="error" variant="filled">
      {error}
    </Alert>
  )}
</Snackbar>


      </Dialog> */}


<Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
<DialogTitle>
          <Box className={classes.heading}>
            <Typography className={classes.textWhite}>{props.t('Edit Order Details')}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
        <DataTable
          columns={[
            { name: 'Name', selector: row => row.name },
            { name: 'Quantity', selector: row => row.quantity, cell: (row, index) => (
              <TextField
                type="number"
                value={row.quantity}
                inputProps={{ style: inputTextStyle }} 
                onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                
              />
            )},
          ]}
          data={modifiedData}
          
        />
        <TextField
          label="Comment"
          fullWidth
          inputProps={{ style: inputTextStyle }}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          margin="dense"
        />
      </DialogContent>
      <DialogActions>
      <Button onClick={handleSubmit}   className={globalClasses.dashbordBtn}>Submit</Button>
        <Button onClick={onClose} className={globalClasses.modalCancleBtn}>Cancel</Button>
       
      </DialogActions>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={() => setOpenSnack(false)}>
        {success ? <Alert severity="success">{success}</Alert> : <Alert severity="error">{error}</Alert>}
      </Snackbar>
    </Dialog>

      {/* Confirmation Modal */}
      <Dialog open={showCancelOrderModal} onClose={() => setShowCancelOrderModal(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box className={classes.heading}>
            <Typography className={classes.textWhite}>{props.t('Confirm Cancellation')}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ color: 'black' }}>
            {props.t('All items removed!! Please Cancel the Order')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelOrder} className={globalClasses.dashbordBtn}>
            Yes
          </Button>
          <Button onClick={() => setShowCancelOrderModal(false)} className={globalClasses.modalCancleBtn}>
            No
          </Button>
        </DialogActions>
        <Snackbar
  open={openSnack}
  autoHideDuration={6000}
  onClose={handleCloseSnack}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  {success ? (
    <Alert onClose={handleCloseSnack} severity="success" variant="filled">
      {success}
    </Alert>
  ) : (
    <Alert onClose={handleCloseSnack} severity="error" variant="filled">
      {error}
    </Alert>
  )}
</Snackbar>


      </Dialog>
    </>
  );
}

export default withTranslation()(EditOrderByWorker);
