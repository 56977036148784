import React, { useRef, useState } from 'react';
import { useMutation, gql, useQuery } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import {
  Button, Box, Typography, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar,
} from '@mui/material';
import useStyles from './styles';
import useStyless from '../Section/styles';
import useGlobalStyles from '../../utils/globalStyles';
import { CancelOrder, createcsadmin, getModifiedOrderDetails, modifyorder, ProcessOrderRefund } from '../../apollo';
import DataTable from 'react-data-table-component';
import TableHeader from '../TableHeader';
import CustomLoader from '../Loader/CustomLoader';
import { customStyles } from '../../utils/modifiedOrderTable';

const CREATE_LOCAL_STORE_ADMIN = gql`${createcsadmin}`;
const EDIT__LOCAL_STORE_ADMIN = gql`${modifyorder}`;
const EDIT_ORDER_REFUND = gql`${ProcessOrderRefund}`;
const CANCEL_ORDER = gql`${CancelOrder}`;
const GET_ORDERDETAILS = gql` ${getModifiedOrderDetails}`
function ViewEditORderDetails(props) {
  const { onClose, refetch, payMethod, sellingPrice,customerType } = props;
  console.log(props.vendor ,"data from parent ")
  const formRef = useRef();
  // const [error, setError] = useState('');
  // const [success, setSuccess] = useState('');
  // const [openSnack, setOpenSnack] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const userType = JSON.parse(localStorage.getItem('user-native'));
  const orderId = localStorage.getItem('orderId');
  const classess = useStyless();
  const inputTextStyle = { color: 'black' };
  const [formData, setFormData] = useState({
    id: userType._id,
    name: props.vendor ? props.vendor.name : '',
    quantity: props.vendor ? props.vendor.quantity : '',
    instruction: '',
    skuId: props.vendor ? props.vendor.skuId : '',
    productId: props.vendor ? props.vendor.productId : '',
  });

  const [modifiedData, setModifiedData] = useState(props.vendor);
  const [comment, setComment] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
console.log(modifiedData ,"modifiedData")
const orderDetails = JSON.parse(localStorage.getItem("OrderData"))
 const { loading: loadingQuery, data } = useQuery(
    GET_ORDERDETAILS, {
    variables: {
      orderId: orderDetails._id
    }
  ,
  fetchPolicy: "no-cache"
  }
  )
  const [modifyOrder] = useMutation(EDIT__LOCAL_STORE_ADMIN, {
    onCompleted: (data) => {
      setSuccess("Modified Successfully");
      setOpenSnack(true);
      refetch();
      setTimeout(() => onClose(), 2000);
    },
    onError: (error) => {
      setError(error.message);
      setOpenSnack(true);
    },
  });

  const handleQuantityChange = (index, value) => {
    if (value < 0 || value > props.vendor[index].quantity) return;
    const updatedData = [...modifiedData];
    updatedData[index].quantity = value;
    setModifiedData(updatedData);
  };

  const getRefundAmount = () => {
    if (!data || !data.getModifiedOrderDetails) return 0; 
    return data.getModifiedOrderDetails.refundAmount || 0; 
  };
  
  const handleSubmit = async () => {
 
    try {
    
  
      // If payment method is online (1), process the refund
      if (payMethod === 1) {
        const refundAmount = getRefundAmount(); // Function to calculate refund amount
  
        await processOrderRefund({
          variables: {
            orderId,
            refundAmount,
            reason: formData.instruction,
            isOrderCancelled: parseInt(formData.quantity) === 0,
          },
        });
        setTimeout(() => onClose(), 2000);
        setSuccess("Refund processed successfully!");
        setOpenSnack(true);
        
      }
    } catch (error) {
      setError(error.message || "Something went wrong!");
      setOpenSnack(true);
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'quantity' && parseInt(value) > props.vendor.quantity) {
      setError('Please enter a quantity less than or equal to the current quantity.');
      setOpenSnack(true);
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const onCompleted = async (data) => {
    setError('');
    setSuccess('Quantity Updated Successfully');
    setOpenSnack(true);
    refetch();

    if (payMethod && payMethod === 1) {
      await processOrderRefund({
        variables: {
          orderId,
          refundAmount: sellingPrice,
          reason: formData.instruction,
          isOrderCancelled: parseInt(formData.quantity) === 0,
        },
      });
    }
  };

  const onError = (error) => {
    setSuccess('');
    const errorMessage =
    error &&  error.graphQLErrors[0] &&  error.graphQLErrors[0].message || 'Something went wrong!';
    if (errorMessage === 'All items removed!! Please Cancel the Order') {
      setShowCancelOrderModal(true);
    } else {
      setError(errorMessage);
      setOpenSnack(true);
    }
  };

  const [mutate] = useMutation(props.vendor ? EDIT__LOCAL_STORE_ADMIN : CREATE_LOCAL_STORE_ADMIN, {
    onCompleted,
    onError,
  });

  const [processOrderRefund] = useMutation(EDIT_ORDER_REFUND);
  const [cancelOrder] = useMutation(CANCEL_ORDER, {
    onCompleted: (data) => {
      console.log("i am comming here " ,data.cancelOrder? data.cancelOrder: "nothing")

      if (data.cancelOrder) {
        setError(''); // Clear previous errors
        setSuccess(data.cancelOrder.message); // Set success message
        setOpenSnack(true); // Trigger Snackbar display
        setShowCancelOrderModal(false);
        refetch();
        setTimeout(hideAlert, 3000);
        
      }
     
    },
    onError: (error) => {
      setError(error.message || 'Failed to cancel the order');
      setOpenSnack(true);
    },
  });
  console.log("i am comming here " ,success ,error ,openSnack)
  const hideAlert = () => {
    setError('');
    setSuccess('');
    setOpenSnack(false); 
    onClose();
  };
  const handleCancelOrder = () => {
    cancelOrder({ variables: { orderId } });
  };

  const handleCloseSnack = () => {
    setError('');
    setSuccess('');
    setOpenSnack(false);
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const getSellingPrice = (prices, customerType) => {
    if (!Array.isArray(prices)) {
        console.error("Invalid prices data:", prices);
        return "N/A";
    }

    const priceEntry = prices.find(price => price.customerType === customerType);
    return priceEntry ? priceEntry.sellingPrice : "N/A";
};


  const modifications = data && data.getModifiedOrderDetails.modifications || [];

// Step 1: Get the first modification's originalItems
const firstModification = modifications[0] || {};
const originalItemsMap = new Map();
(firstModification.originalItems || []).forEach(item => {
    originalItemsMap.set(item.productId, item.quantity);
});

// Step 2: Get the latest modification's modifiedItems
const lastModification = modifications[modifications.length - 1] || {};
const modifiedItemsMap = new Map();
(lastModification.modifiedItems || []).forEach(item => {
    modifiedItemsMap.set(item.productId, item.quantity);
});

// Step 3: Create a final list of items with original and modified quantities
const finalItems = Array.from(new Set([...originalItemsMap.keys(), ...modifiedItemsMap.keys()])).map(productId => {
    const modifiedItem = lastModification.modifiedItems 
        ? lastModification.modifiedItems.find(item => item.productId === productId) 
        : null;

    return {
        productId,
        name: modifiedItem && modifiedItem.name ? modifiedItem.name : "",
        image: modifiedItem && modifiedItem.image ? modifiedItem.image : "",
        originalQuantity: originalItemsMap.get(productId) || 0,
        modifiedQuantity: modifiedItemsMap.get(productId) || 0,
        mrp:modifiedItem && modifiedItem.mrp ?modifiedItem.mrp : 0, // Ensure MRP is included
        prices:data && data.getModifiedOrderDetails.items.find(item => item.productId === productId).prices || [] // Ensure prices are available
    };
});


// Step 4: Define the columns for the table
const columns = [
    {
        name: "Sl No",
        selector: (row, index) => index + 1
    },
    {
        name: "Name",
        selector: row => row.name || "N/A",
        style: { cursor: 'pointer', minWidth: "200px" }
    },
    {
        name: "Image",
        cell: row => row.image ? <img src={row.image} alt="Product" style={{ width: 50 }} /> : "N/A",
        // style: { cursor: 'pointer', minWidth: "200px" }
    },
    {
        name: "Original Quantity",
        selector: row => row.originalQuantity || 0,
        style: { cursor: 'pointer', minWidth: "200px" }
    },
    {
        name: "Modified Quantity",
        selector: row => row.modifiedQuantity || 0,
        style: { cursor: 'pointer', minWidth: "200px" }
    },
    {
        name: "MRP",
        selector: row => row.mrp || 0, // Display MRP with default 0
        style: { cursor: 'pointer', minWidth: "200px" }
    },
    {
        name: "Selling Price",
        cell: row => {
            const sellingPrice = getSellingPrice(row.prices, customerType);
            return sellingPrice !== "N/A" ? `₹${sellingPrice.toFixed(2)}` : "N/A";
        },
        style: { cursor: 'pointer', minWidth: "200px" }
    },
    {
        name: "Net Price",
        cell: row => {
            const sellingPrice = getSellingPrice(row.prices, customerType);
            return sellingPrice !== "N/A" ? `₹${(row.modifiedQuantity * sellingPrice).toFixed(2)}` : "N/A";
        },
        style: { cursor: 'pointer', minWidth: "200px" }
    }
];


//  const columns = [
//     {
//       name: "Sl No",
//       selector: (row, index) => index + 1
//     },

//     {
//       name: "Name",
//       selector: "name",
//       style: { cursor: 'pointer', minWidth: "200px" },
//     },
//     {
//       name: "Image",
//       cell: row => <img src={row.image} alt="Product" style={{ width: 50 }} />
//     },
//     {
//       name: "Quantity",
//       selector: "quantity"
//     },
//     {
//       name: "MRP",
//       selector: "mrp"
//     },
//     {
//       name: "Selling Price",
//       cell: (row) => getSellingPrice(row.prices, customerType)
//     },
//     {
//       name: "Net Price", // Add Net Price column
//       cell: (row) => {
//         const sellingPrice = getSellingPrice(row.prices, customerType); // Get selling price
//         return row.quantity * sellingPrice; // Calculate net price
//       }
//     },
//     // If workerType !== 0, don't include the column
   
//   ];

  const filtered =
  data &&
  data.getModifiedOrderDetails.items
  return (
    <>
     
<Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
<DialogTitle>
          <Box className={classes.heading}>
            <Typography className={classes.textWhite}>{props.t('Edit Order Details')}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
       <Grid container item xs={12} md={6}>
       <Grid item xs={6} md={6} >
                         <Typography className={classess.typographyOrder}>
                          Original Paid Amount
       
                         </Typography>
                       </Grid>
                       <Grid item xs={6} md={6}>
                         <Typography className={classess.typographyOrder}>
                           {(data && data.getModifiedOrderDetails) ? data.getModifiedOrderDetails.paidAmount : "N/A"}
                         </Typography>
                       </Grid>
                       <Grid item xs={6} md={6} >
                         <Typography className={classess.typographyOrder}>
                          Modified Order Amount
       
                         </Typography>
                       </Grid>
                       <Grid item xs={6} md={6}>
                         <Typography className={classess.typographyOrder}>
                           {(data && data.getModifiedOrderDetails) ? data.getModifiedOrderDetails.orderAmount : "N/A"}
                         </Typography>
                       </Grid>
                       <Grid item xs={6} md={6} >
                         <Typography className={classess.typographyOrder}>
                          Refund Amount
       
                         </Typography>
                       </Grid>
                       <Grid item xs={6} md={6}>
                         <Typography className={classess.typographyOrder}>
                           {(data && data.getModifiedOrderDetails) ? data.getModifiedOrderDetails.refundAmount : "N/A"}
                         </Typography>
                       </Grid>
                      
                       <Grid item xs={6} md={6} >
                         <Typography className={classess.typographyOrder}>
                         Total Refund Proccessed
       
                         </Typography>
                       </Grid>
                       <Grid item xs={6} md={6}>
                         <Typography className={classess.typographyOrder}>
                           {(data && data.getModifiedOrderDetails) ? data.getModifiedOrderDetails.totalRefundProccessed : "N/A"}
                         </Typography>
                       </Grid>
                       <Grid item xs={6} md={6} >
                         <Typography className={classess.typographyOrder}>
                          Refund Amount Pending
       
                         </Typography>
                       </Grid>
                       <Grid item xs={6} md={6}>
                         <Typography className={classess.typographyOrder}>
                           {(data && data.getModifiedOrderDetails) ? data.getModifiedOrderDetails.totalRefundPending : "N/A"}
                         </Typography>
                       </Grid>
                       </Grid>
                   
        <DataTable
        
          title={<TableHeader title='Orders' />}
          columns={columns}
          data={finalItems }
          pagination
          progressPending={loadingQuery}
          progressComponent={<CustomLoader />}
          customStyles={customStyles}
        // selectableRows
        />
       
   
      </DialogContent>
      <DialogActions>
      {(data && data.getModifiedOrderDetails) && data.getModifiedOrderDetails.totalRefundPending>0 && data.getModifiedOrderDetails.paymentMethod===1 ?  <Button onClick={handleSubmit}   className={globalClasses.dashbordBtn}>Process Refund</Button> :  null}
       
        <Button onClick={onClose} className={globalClasses.modalCancleBtn}>Cancel</Button>

       
      </DialogActions>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={() => setOpenSnack(false)}>
        {success ? <Alert severity="success">{success}</Alert> : <Alert severity="error">{error}</Alert>}
      </Snackbar>
    </Dialog>

      {/* Confirmation Modal */}
      <Dialog open={showCancelOrderModal} onClose={() => setShowCancelOrderModal(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box className={classes.heading}>
            <Typography className={classes.textWhite}>{props.t('Confirm Cancellation')}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ color: 'black' }}>
            {props.t('All items removed!! Please Cancel the Order')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelOrder} className={globalClasses.dashbordBtn}>
            Yes
          </Button>
          <Button onClick={() => setShowCancelOrderModal(false)} className={globalClasses.modalCancleBtn}>
            No
          </Button>
        </DialogActions>
        <Snackbar
  open={openSnack}
  autoHideDuration={6000}
  onClose={handleCloseSnack}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  {success ? (
    <Alert onClose={handleCloseSnack} severity="success" variant="filled">
      {success}
    </Alert>
  ) : (
    <Alert onClose={handleCloseSnack} severity="error" variant="filled">
      {error}
    </Alert>
  )}
</Snackbar>


      </Dialog>
    </>
  );
}

export default withTranslation()(ViewEditORderDetails);
