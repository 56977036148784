
import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { wokerOrderstatusUpdate, getOrderDetailsforworker, RetryRefund, getInvoiceByOrderId } from '../apollo'
import DataTable from 'react-data-table-component'
import { customStyles } from '../utils/orderTable'
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper, Box,
} from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@material-ui/lab/Alert'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import TableHeader from '../components/TableHeader'
import useStyles from '../components/Section/styles';
import EditOrderByWorker from '../components/EditOrder/EditOrderByWorker'
import moment from 'moment'; // Import moment library
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Icon } from '@iconify/react';
import Divider from '@mui/material/Divider';
import { MdOutlineDirectionsBike, MdOutlineCheckCircle, MdOutlineCancel, MdErrorOutline, MdLocalShipping, MdDone, MdAssignment } from 'react-icons/md';
import {
  MdPersonAdd,
  MdPerson,
  MdPersonOutline,
  MdPersonPin,
  MdHourglassEmpty,
  MdAccessTime,
} from 'react-icons/md';
import ConfigurableValues from '../config/constants'
import { SetOrderItemChecked } from '../apollo/mutations'
import { Checkbox } from '@mui/material';
import ChangesDeliverySlot from '../components/ChangeDeliverySlot/ChangesDeliverySlot'
import { renderEjsTemplate } from '../utils/invoiceEJS'
import html2pdf from "html2pdf.js";
import ejs from 'ejs';
import ViewEditORderDetails from '../components/EditOrder/ViewEditORderDetails'

const GET_ORDERDETAILS = gql`
  ${getOrderDetailsforworker}
`

const UPDATEWORKERORDERSTATUS = gql`
  ${wokerOrderstatusUpdate}
`
const SET_ORDER_ITEM_CHECKED = gql`
   ${SetOrderItemChecked}
`
const RETRY_REFUND = gql`
${RetryRefund}`
const GET_INVOICE_BY_ORDER_ID = gql`
${getInvoiceByOrderId}`
const PackageOrderDetails = props => {
  const history = useHistory();
  const {
    SERVER_URL,
  } = ConfigurableValues()
  const { t, } = props;
  const [timeSlotModal, setTimeSlotModalModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [viewDetailsModal, setViewDetailModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const userDetails = JSON.parse(localStorage.getItem('user-native'))
  const workerType = JSON.parse(localStorage.getItem('user-native')).workerType
  const orderDetails = JSON.parse(localStorage.getItem("OrderData"))
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  var [openSnackk, setopenSnackk] = useState(false);
  const golbalClasses = useGlobalStyles()
  const client = useApolloClient();
  const [sellingPrice, setSellingPrice] = useState(0)
  const [reload ,setreload] = useState(false)

  const renderEjsTemplate = (data) => {
    // EJS template as a string
    const template = `
     <!DOCTYPE html>
<html>
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<h2>INVOICE</h2>
<p style="font-weight: bold">NATIVE ROOTS RETAIL PRIVATE LIMITED<br/>
No.53, Kaveri Layout, Hebbal, Kempapura<br/>
 Bangalore North, Bangalore - 560024, Karnataka</p>

<table width=950>
  <tr>
    <td colspan="7" style="font-weight: bold">Details of Receiver</td>
    <td colspan="2" style="font-weight: bold">Date</td>
      
   <td colspan="5">
  <%= new Date(invoice.invoiceDate).toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }) %>
  &nbsp; <!-- Adds a gap between date and time -->
  <%= new Date(invoice.invoiceDate).toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit' }) %>
</td>


  </tr>
  <tr>
    <td colspan="2" style="font-weight: bold">Name</td>
    <td colspan="5"><%= invoice.customerName %></td>
    <td colspan="2" style="font-weight: bold">EInvoice #</td>
    <td colspan="5"><%= invoice._id %></td>
  </tr>
  <tr>
    <td colspan="2" style="font-weight: bold">Address</td>
    <td colspan="5"><%= invoice.deliveryAddress.details +","+invoice.deliveryAddress.deliveryAddress %></td>
   
     <td colspan="7"><%= "Phone: "+invoice.customerPhone%></td>
  </tr>
    <tr>
    <td colspan="2" style="font-weight: bold">State</td>
    <td colspan="5">Karnataka</td>
    <td colspan="7"></td>
   
  </tr>
   
    <tr>
    <td colspan="2" style="font-weight: bold">State code</td>
    <td colspan="5"></td>
   
     <td colspan="7"></td>
  </tr>
   
    <tr>
        <td colspan="2" style="font-weight: bold">GSTIN</td>
        <td colspan="5"><%= invoice.customerGST %></td>
        <td colspan="2" style="font-weight: bold">Order Id: </td>
        <td colspan="5"><%= invoice.orderId %></td>
    
  </tr>
     <td colspan="14"><br/></td>
 
  </tr>
  <tr height=16>
  <td rowspan=2 height=32 class=xl80 width=31 style='height:25.5pt;border-top:
  none;width:23pt;font-weight: bold'>SL NO</td>
  <td rowspan=2 class=xl80 width=116 style='border-top:none;width:87pt;font-weight: bold'>Description
  of Goods</td>
  <td rowspan=2 class=xl80 width=56 style='border-top:none;width:42pt;font-weight: bold'>HSN Code</td>
  <td rowspan=2 class=xl80 width=35 style='border-top:none;width:26pt;font-weight: bold'>Ord Qty</td>

  <td rowspan=2 class=xl80 width=52 style='border-top:none;width:39pt;font-weight: bold'>Units</td>
  <td rowspan=2 class=xl80 width=59 style='border-top:none;width:44pt;font-weight: bold'>Rate</td>
  <td rowspan=2 class=xl80 width=71 style='border-top:none;width:53pt;font-weight: bold'>Total</td>
  <td rowspan=2 class=xl80 width=71 style='border-top:none;width:53pt;font-weight: bold'>Discount</td>
  <td rowspan=2 class=xl80 width=71 style='border-top:none;width:53pt;font-weight: bold'>Taxable
  Value</td>
  <td colspan=2 class=xl80 width=107 style='border-left:none;width:80pt;font-weight: bold'>CGST</td>
  <td colspan=2 class=xl80 width=107 style='border-left:none;width:80pt;font-weight: bold'>SGST</td>
 </tr>
  <tr height=16>
  <td height=16 class=xl67 width=44 style='height:12.75pt;border-top:none;
  border-left:none;width:33pt'>Rate</td>
  <td class=xl67 width=63 style='border-top:none;border-left:none;width:47pt;font-weight: bold'>Amount</td>
  <td class=xl67 width=44 style='border-top:none;border-left:none;width:33pt;font-weight: bold'>Rate</td>
  <td class=xl67 width=63 style='border-top:none;border-left:none;width:47pt;font-weight: bold'>Amount</td>
 </tr>
 <!-- This row will be repeated for each items-->
    <% if (invoice.items.length > 0) { %> <%
      let i = 0
    invoice.items.forEach(item => { ++i %>
  <tr height=33>
  <td height=33 class=xl65 align=right style='height:25.5pt;border-top:none'><%= i %></td>
  <td class=xl68 width=116 style='border-top:none;border-left:none;width:87pt'><%= item.name %></td>
  <td class=xl66 width=56 style='border-top:none;border-left:none;width:42pt'><%= item.hsn %></td>
  <td class=xl69 style='border-top:none;border-left:none'><%= item.quantity %></td>

  <td class=xl69><%= item.unit %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= item.sellingPrice %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= item.cost %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= item.discount %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= item.taxableValue %></td>
  <td class=xl71 align=right style='border-top:none;border-left:none'><%= item.CGST.rate %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= item.CGST.amount %></td>
  <td class=xl71 align=right style='border-top:none;border-left:none'><%= item.SGST.rate %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= item.SGST.amount %></td>
 </tr>
 <% });%> <% }%>
 <!-- Items row end here-->
  <tr height=16 >
  <td colspan=6 height=16 class=xl79 style='height:12.75pt; text-align: right' >Total</td>
  <td class=xl70 align=right style='border-top:none;border-left:none;'><%= invoice.billDetails.itemsTotal %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= invoice.billDetails.itemsSavings %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= invoice.billDetails.itemsTaxableValue %></td>
  <td colspan=2 class=xl70 align=right style='border-left:none'><%= invoice.billDetails.itemsCGST %></td>
  <td colspan=2 class=xl70 align=right style='border-left:none'><%= invoice.billDetails.itemsSGST %></td>
 </tr>
  <tr height=16 >
  <td colspan=8 height=16 class=xl72 style='height:12.75pt;font-weight: bold;text-align: right'>Total Invoice
  Value(in figures)</td>
  <td colspan=6 class=xl77 align=right style='border-left:none;font-weight: bold'><%= invoice.billDetails.grandTotal %></td>
 </tr>
 <tr height=16 >
  <td colspan=8 height=16 class=xl72 style='height:12.75pt;font-weight: bold;text-align: right'>Total Invoice
  Value(in words)</td>
  <td colspan=6 class=xl75 width=356 style='border-left:none;width:266pt;font-weight: bold;text-align: right'><%= invoice.billDetails.words %></td>
  
 </tr>
<tr height=16>
  <td colspan=8 height=16 class=xl72 style='height:12.75pt;font-weight: bold;text-align: right'>Net Total</td>
  <td colspan=6 class=xl78 style='border-left:none;font-weight: bold;text-align: right'><%= invoice.billDetails.grandTotal %></td>
 </tr>
</table>
</body>
</html>


    `;
  
    // Use ejs.render to inject data into the template
    return ejs.render(template, data);
  };
  const [setOrderItemChecked] = useMutation(SET_ORDER_ITEM_CHECKED);
  const [updateOrderStatus] = useMutation(
    UPDATEWORKERORDERSTATUS, {
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }
  );
  useEffect(()=>{

  },[reload])

  const [getInvoice, { data:invoiceData, loading, error:invoiceError }] = useLazyQuery(GET_INVOICE_BY_ORDER_ID);

  const formatDate = date => {
    const formattedDate = moment(date).format('Do MMMM YYYY, hh:mm a'); // Assuming the end time is 2 hours ahead
    return `${formattedDate}`;
  };



  const handleBackClick = () => {
    if (workerType === 0) {
      history.push('/package_admin/orderlist');
    } else if (workerType === 1) {
      history.push('/billing_admin/billinglist');
    }
  };
  const handleReadyButtonClickAccept = async (row) => {
    try {
      let status;
      if (workerType === 0) {
        status = 0;
      } else if (workerType === 1) {
        status = 0;
      } else {
        // Handle other workerType values if needed
        status = 1; // Default status value
      }

      const { data, error } = await updateOrderStatus({
        variables: {
          orderId: orderDetails._id,
          status: status
        }
      });
      if (data) {
        const message = "Order Accepted."
        errorSetter('');
        successSetter(message);
        setTimeout(hideAlert, 3000);
        setopenSnackk(true)
        refetch()
      }
      if (error) {
        errorSetter("Something Went Wrong");
        // successSetter(message);
        setTimeout(hideAlert, 3000);
        setopenSnackk(true)
      }
    } catch (error) {
    }
  };
  const handleReadyButtonClick = async (row) => {
    try {
      let status;
      if (workerType === 0) {
        status = 1;
      } else if (workerType === 1) {
        status = 1;
      } else {
        // Handle other workerType values if needed
        status = 1; // Default status value
      }
      const { data, error } = await updateOrderStatus({
        variables: {
          orderId: orderDetails._id,
          status: status
        }
      });
      if (data) {

        const message = t(' Order Successfully Completed');
        errorSetter('');
        successSetter(message);
        refetch()
        openSnackk = true
        setopenSnackk(openSnackk)
        setTimeout(hideAlert, 3000);
        if (workerType === 0) {
          history.push('/package_admin/orderList');
        }

      } else if (!data.updateWorkerOrderStatus) {
        const message = t('Unable To Complete The Order!');

        errorSetter(message);
        refetch()
        openSnackk = true
        setopenSnackk(openSnackk)
        setTimeout(hideAlert, 3000);
      }

      if (error) {
        const message = t('Something Went Wrong!');
        errorSetter(message);
        successSetter('');
        refetch()
        openSnackk = true
        setopenSnackk(openSnackk)
        setTimeout(hideAlert, 3000);
      }
    } catch (error) {
    }
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    openSnackk = false
    setopenSnackk(openSnackk)
  };

  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };

  const closeViewDetailModal = () => {
    setViewDetailModal(false);
    // setAddVendorModal(false);
  };
  const closeTimeSlotModal = () => {
    setTimeSlotModalModal(false);
    // setAddVendorModal(false);
  };
  const { loading: loadingQuery, data, refetch } = useQuery(
    GET_ORDERDETAILS, {
    variables: {
      orderId: orderDetails._id
    }
  ,
  fetchPolicy: "no-cache"
  }
  )
  const handlereload =()=>{
    setreload(!reload)
  }
  useEffect(()=>{

  },[refetch])
  const [retryRefund, { data: refundData }] = useMutation(RETRY_REFUND, {
    onCompleted: (refundData) => {
      if (refundData) {
        const message = t('Refunding  Updated Successfully')
        errorSetter('');
        successSetter(message);
        setTimeout(hideAlert, 3000);
        setopenSnackk(true)
      } else {

      }
      refetch()
    },
    onError: (graphQLErrors, networkError) => {
      successSetter('');
      if (graphQLErrors) {
        // const errorMessage = graphQLErrors[0].message;
        const errorMessage = "Something Went Wrong "
        error = graphQLErrors[0].message
        errorSetter(errorMessage);
        setopenSnackk(true)
      }

      else if (networkError) errorSetter(networkError.result.errors[0].message);
      else errorSetter('Something went wrong!');
      setTimeout(hideAlert, 3000);
      setopenSnackk(true)
    }
  });

  const handleRetryClick = () => {
    const orderId = orderDetails._id
    retryRefund({
      variables: { orderId }
    });
  };


  const filtered =
    data &&
    data.getOrderDetailsById.items
  const payMethodVar = data && data.getOrderDetailsById.paymentMethod

  const customerType = data && data.getOrderDetailsById && data.getOrderDetailsById.customer
  ? data.getOrderDetailsById.customer.customerType
  : 'N/A';


  const billDetails = data ? data.getOrderDetailsById.billDetails : null
  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
    let sellingPrice = getSellingPrice(vendor.prices, customerType)
    setSellingPrice(sellingPrice)
  }
  const openVeiwDetailModal = vendor => {
    setViewDetailModal(!viewDetailsModal)
    console.log(viewDetailsModal ,"viewDetailsModal")
    setVendor(vendor)
    // let sellingPrice = getSellingPrice(vendor.prices, customerType)
    // setSellingPrice(sellingPrice)
  }
  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])

  const getSellingPrice = (prices, customerType) => {
    if (!Array.isArray(prices)) {
      console.error("Invalid prices data:", prices);
      return "N/A";
    }
  
    const price = prices.find(price => price.customerType === customerType);
    return price ? price.sellingPrice : "N/A";
  };
  
  const handleCheckBoxToggle = async (item) => {
   
  
    const payload = {
      orderId: orderDetails._id, // Assuming you have order details available
      itemId: `${item.productId}_${item.skuId}`,
      checked: !item.packingChecked
    };
  
    try {
      const response = await setOrderItemChecked({ variables: payload });
      if (response) {
        refetch()
        // Successfully updated, you can update the UI accordingly
    
      }
    } catch (error) {
      console.error('Error updating packing status', error);
    }
  };
  const handleCheckBoxToggleBilling = async (item) => {
   
  
    const payload = {
      orderId: orderDetails._id, // Assuming you have order details available
      itemId: `${item.productId}_${item.skuId}`,
      checked: !item.billingChecked
    };
  
    try {
      const response = await setOrderItemChecked({ variables: payload });
      if (response) {
        refetch()
        // Successfully updated, you can update the UI accordingly
        console.log('Packing status updated');
      }
    } catch (error) {
      console.error('Error updating packing status', error);
    }
  };
  const columns = [
    {
      name: "Sl No",
      selector: (row, index) => index + 1
    },

    {
      name: "Name",
      selector: "name",
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Image",
      cell: row => <img src={row.image} alt="Product" style={{ width: 50 }} />
    },
    {
      name: "Quantity",
      selector: "quantity"
    },
    {
      name: "MRP",
      selector: "mrp"
    },
    {
      name: "Selling Price",
      cell: (row) => getSellingPrice(row.prices, customerType)
    },
    {
      name: "Net Price", // Add Net Price column
      cell: (row) => {
        const sellingPrice = getSellingPrice(row.prices, customerType); // Get selling price
        return row.quantity * sellingPrice; // Calculate net price
      }
    },
    ...(workerType === 0 && data && data.getOrderDetailsById.orderState.status === 2 ? [ // Conditionally show for package admin
      {
        name: "Packing Checked",
        cell: (row) => (
          <Checkbox
            checked={row.packingChecked}
            onChange={() => handleCheckBoxToggle(row, 'packingChecked')} // Differentiate between packing and billing
            color="primary"
          />
        )
      }
    ] : []), // If workerType !== 0, don't include the column
    ...(workerType === 1 &&   data && data.getOrderDetailsById.orderState.status === 3 ? [ // Conditionally show for billing admin
      {
        name: "Billing Checked",
        cell: (row) => (
          <Checkbox
            checked={row.billingChecked}
            onChange={() => handleCheckBoxToggleBilling(row, 'billingChecked')} // Differentiate between packing and billing
            color="primary"
          />
        )
      }
    ] : []), 
   
    {
      name: "Action",
      cell: row => <>{actionButtons(row)}</>
    }
  ];
  const columnsPDF = [
    {
      name: "Name",
      selector: "name",

      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Description",
      selector: "description",

      style: { cursor: 'pointer', minWidth: "300px" },
    },

    {
      name: "Quantity",
      selector: "quantity"
    },
    {
      name: "MRP",
      selector: "mrp"
    },
    {
      name: "Selling Price",
      cell: (row) => getSellingPrice(row.prices, customerType)
    },

  ];

  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>

        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              {data && data.getOrderDetailsById.orderState.workerStatus === 2 || data && data.getOrderDetailsById.orderState.workerStatus === 5 ?
                <MenuItem
                  onClick={e => {
                    e.preventDefault()
                    setTimeout(() => {
                    }, 5000)
                    // uncomment this for paud version
                    toggleModal(row);
                    localStorage.setItem('orderId', orderDetails._id)
                  }}
                  style={{ height: 25 }}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" style={{ color: 'green' }} />
                  </ListItemIcon>
                  <Typography color="green">{t('Edit')}</Typography>
                </MenuItem>
                :
                <div onClick={e => {
                  e.preventDefault()
                  openSnackk = true
                  setopenSnackk(openSnackk)
                  errorSetter("Please Accept the Order Before Edit.");
                  // successSetter(message);
                  setTimeout(hideAlert, 3000);
                }}>
                  <MenuItem
                    disabled

                    style={{ height: 25 }}>
                    <ListItemIcon>
                      <EditIcon fontSize="small" style={{ color: 'green' }} />
                    </ListItemIcon>
                    <Typography color="green">{t('Edit')}</Typography>
                  </MenuItem>
                </div>
              }
            </Menu>
          </Paper>
        </div>
      </>
    )
  }

  const getOrderStatusWord = (statusNumber) => {
    const statusMap = {
      0: { text: "INITIATED", icon: <MdAssignment style={{ color: '#66a1ff' }} /> },
      1: { text: "CONFIRMED", icon: <MdDone style={{ color: '#66a1ff' }} /> },
      2: { text: "PACKING", icon: <MdOutlineCheckCircle style={{ color: '#66a1ff' }} /> },
      3: { text: "BILLING", icon: <MdAssignment style={{ color: '#66a1ff' }} /> },
      4: { text: "READY FOR DELIVERY", icon: <MdLocalShipping style={{ color: '#66a1ff' }} /> },
      5: { text: "RIDER ASSIGNED", icon: <MdOutlineDirectionsBike style={{ color: '#66a1ff' }} /> },
      6: { text: "OUT FOR DELIVERY", icon: <MdLocalShipping style={{ color: '#66a1ff' }} /> },
      7: { text: "DELIVERED", icon: <MdDone style={{ color: '#66a1ff' }} /> },
      8: { text: "CANCELLED", icon: <MdOutlineCancel style={{ color: '#66a1ff' }} /> },
      9: { text: "FAILED OUT OF STOCK", icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> },
      10: { text: "FAILED INVALID DELIVERY ADDRESS", icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> },
      11: { text: "NO ITEM CART", icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> },
      12: { text: "NO ITEM CART", icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> },
    };

    const status = statusMap[statusNumber] || { text: "N/A", icon: null };
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {status.icon}
        <span style={{ marginLeft: '8px' }}>{status.text}</span>
      </div>
    );
  };

  const getWorkerStatus = (status) => {
    const statusMap = {
      0: { text: 'Not Started', icon: <MdHourglassEmpty style={{ color: '#66a1ff' }} /> },
      1: { text: 'Pp Assigned', icon: <MdPersonAdd style={{ color: '#66a1ff' }} /> },
      2: { text: 'Pp In Progress', icon: <MdPerson style={{ color: '#66a1ff' }} /> },
      3: { text: 'Pp Completed', icon: <MdDone style={{ color: '#66a1ff' }} /> },
      4: { text: 'Billing Assigned', icon: <MdPersonPin style={{ color: '#66a1ff' }} /> },
      5: { text: 'Billing In Progress', icon: <MdAccessTime style={{ color: '#66a1ff' }} /> },
      6: { text: 'Billing Completed', icon: <MdDone style={{ color: '#66a1ff' }} /> },
      7: { text: 'Rider Assign Manual', icon: <MdPersonOutline style={{ color: '#66a1ff' }} /> },
      8: { text: 'Rider Assigned', icon: <MdOutlineDirectionsBike style={{ color: '#66a1ff' }} /> },
      9: { text: 'Rider Out For Delivery', icon: <MdLocalShipping style={{ color: '#66a1ff' }} /> },
      10: { text: 'Rider Delivered', icon: <MdDone style={{ color: '#66a1ff' }} /> }
    };

    const workerStatus = statusMap[status] || { text: 'N/A', icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> };
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {workerStatus.icon}
        <span style={{ marginLeft: '8px' }}>{workerStatus.text}</span>
      </div>
    );
  };


  const getPaymentMethod = (statusNumber) => {
    const statusMap = {
      0: "COD",
      1: "ONLINE",

    };

    return statusMap[statusNumber] || "N/A";
  };

  const getPaymentStatus = (statusNumber) => {
    const statusMap = {
      0: "PENDING",
      1: "COMPLETED",

    };

    return statusMap[statusNumber] || "N/A";
  };



  const handlePrint = () => {
    const doc = new jsPDF();
    
    // Add 'Net Price' column to the table header
    const tableColumn = ['Sl No', 'Name', 'Quantity', 'MRP', 'Selling Price', 'Net Price']; 
    
    // Adjust tableRows to remove 'description' and add 'Net Price'
    const tableRows = filtered.map((row, index) => {
      const sellingPrice = getSellingPrice(row.prices, customerType); // Get selling price
      const netPrice = row.quantity * sellingPrice; // Calculate net price
      return [
        index + 1,               // Sl No
        row.name,                // Name
        row.quantity,            // Quantity
        row.mrp,                 // MRP
        sellingPrice,            // Selling Price
        netPrice                 // Net Price (quantity * selling price)
      ];
    });
  
    doc.setFontSize(12);
    let currentY = 20;
  
    // Header
    doc.setFontSize(16);
    doc.text('ORDER DETAILS', doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });
    doc.setFontSize(12);
  
    // Order Details
    doc.text(`Order Number: ${orderDetails._id}`, 14, currentY);
    currentY += 6;
    doc.text(`Order Date: ${formatDate(orderDetails.createdAt)}`, 14, currentY);
    currentY += 6;
    doc.text(`Customer Name: ${(data && data.getOrderDetailsById) ? data.getOrderDetailsById.customer.name : 'N/A'}`, 14, currentY);
    currentY += 6;
    doc.text(`Customer Phone: ${(data && data.getOrderDetailsById) ? data.getOrderDetailsById.customer.phone : 'N/A'}`, 14, currentY);
    currentY += 6;
  
    // Displaying the total count of items
    const itemCount = filtered.length;
    doc.text(`Item Count: ${itemCount}`, 14, currentY);
    currentY += 6;
  
     // Handle long delivery address
     const address = data && data.getOrderDetailsById && data.getOrderDetailsById.deliveryAddress ?
     ` ${data.getOrderDetailsById.deliveryAddress.details} ,${data.getOrderDetailsById.deliveryAddress.deliveryAddress}`
     :
     'N/A';
    const wrappedAddress = doc.splitTextToSize(address, 180); // Adjust the width as needed
    doc.text('Customer Address:', 14, currentY);
    currentY += 6;
    doc.text(wrappedAddress, 14, currentY);
    currentY += wrappedAddress.length * 6;
  
    const customerTypeText = customerType === 0 ? 'Premium' : customerType === 1 ? 'Regular' : 'N/A';
    doc.text(`Customer Type: ${customerTypeText}`, 14, currentY);
    currentY += 6;
  
    // Payment Details above the table
    doc.text(`Payment Method: ${(data && data.getOrderDetailsById) ? getPaymentMethod(data.getOrderDetailsById.paymentMethod) : 'N/A'}`, 14, currentY);
    currentY += 6;
    doc.text(`Payment Status: ${(data && data.getOrderDetailsById) ? getPaymentStatus(data.getOrderDetailsById.paymentStatus) : 'N/A'}`, 14, currentY);
    currentY += 10;
  
    // Table of Items with the new Net Price column
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: currentY,
      theme: 'grid',
    });
  
    currentY = doc.lastAutoTable.finalY + 10;
    const rightAlignX = doc.internal.pageSize.getWidth() - 30;  // Move the value column a bit to the left
    const labelX = rightAlignX - 70;  // Reduce the gap between the label and value
    const renderBillingDetail = (label, value, strikeThrough = false) => {
      // Customize the symbols based on the label
      let formattedValue;
      
      if (label.includes('Discount') || label.includes('Coupon') || label.includes('Wallet') || label.includes('Nest')|| label.includes('Savings')|| label.includes('ReferralInfo')) {
        formattedValue = value >= 0 ? `-${value}` : `${value}`;  // Always negative for discounts, coupons, wallets, nest
      } else if(label.includes('Delivery') || label.includes('Handling') ){
        formattedValue = value >= 0 ? `+${value}` : `${value}`;  // Positive for delivery and handling charges
      }else {
        formattedValue = value >= 0 ? ` ${value}` : `${value}`;
      }
    
      doc.text(label, labelX, currentY);  // Display label
    
      if (strikeThrough) {
        doc.setTextColor(150);  // Lighter color for strike-through items
        doc.line(rightAlignX - 10, currentY - 2, rightAlignX + 10, currentY - 2); // Strikethrough line
      }
    
      doc.text(formattedValue, rightAlignX, currentY);  // Display formatted value with + or - symbol
      currentY += 6;  // Move to the next line
      doc.setTextColor(0);  // Reset text color to black
    };
    
    // Usage in handlePrint function
    if (billDetails) {
      // Displaying billing details with custom + and - symbols
      renderBillingDetail('MRP:', billDetails.itemsMrp || 0);
      renderBillingDetail('Items Discount:', billDetails.itemSavings || 0);  // Display with -
      renderBillingDetail('Items Total Price:', billDetails.itemsTotal || 0);  // Display with +
    
      // Conditional rendering based on applied status for Coupon, Wallet, Nest
      if (billDetails.couponInfo && billDetails.couponInfo.applied) {
        renderBillingDetail('Coupon:', billDetails.couponInfo.amount || 0);  // Display with -
      }
    
      if (billDetails.walletInfo && billDetails.walletInfo.applied) {
        renderBillingDetail('Wallet:', billDetails.walletInfo.amount || 0);  // Display with -
      }
    
      if (billDetails.nestInfo && billDetails.nestInfo.applied) {
        renderBillingDetail('Nest:', billDetails.nestInfo.amount || 0);  // Display with -
      }
      if (billDetails.referralInfo && billDetails.referralInfo.applied) {
        renderBillingDetail('ReferralInfo:', billDetails.referralInfo.amount || 0);  // Display with -
      }
      // Handle waived charges with strike-through
      if (billDetails.chargesWaived === 3) {
        renderBillingDetail('Delivery Charges:', billDetails.deliveryCharge || 0, true);  // Display with + and strike through
        renderBillingDetail('Handling Charges:', billDetails.handlingCharge || 0, true);  // Display with + and strike through
      } else if (billDetails.chargesWaived === 2) {
        renderBillingDetail('Delivery Charges:', billDetails.deliveryCharge || 0, true);  // Display with + and strike through
        renderBillingDetail('Handling Charges:', billDetails.handlingCharge || 0);  // Display with +
      } else if (billDetails.chargesWaived === 1) {
        renderBillingDetail('Delivery Charges:', billDetails.deliveryCharge || 0);  // Display with +
        renderBillingDetail('Handling Charges:', billDetails.handlingCharge || 0, true);  // Display with + and strike through
      } else {
        renderBillingDetail('Delivery Charges:', billDetails.deliveryCharge || 0);  // Display with +
        renderBillingDetail('Handling Charges:', billDetails.handlingCharge || 0);  // Display with +
      }
    
      // Add the horizontal line and other billing details as before
      const pageWidth = doc.internal.pageSize.getWidth();
      const halfPageWidth = pageWidth / 2;
      const rightSideWidth = pageWidth - 14;
      currentY += 2;
      doc.line(halfPageWidth, currentY, rightSideWidth, currentY);  // Horizontal line
      currentY += 4;
    
      renderBillingDetail('Total Savings:', billDetails.totalSaving || 0);
      renderBillingDetail('Net Price:', billDetails.grandTotal || 0);
    }
    
    
    // Save the document
    doc.save('order-details.pdf');
  };


// Helper function to decode Base64 to JSON
function base64ToJson(base64) {
  // Decode Base64 string
  const jsonString = atob(base64);
  // Parse the JSON string
  return JSON.parse(jsonString);
}

const handleViewDetails = () => {
  const orderId = (data && data.getOrderDetailsById) ? data.getOrderDetailsById._id : null;

  if (!orderId) {
    console.error('Order ID not found');
    return;
  }

  getInvoice({
    variables: { orderId: orderId },
    onCompleted: (response) => {
      const base64Invoice = response.getInvoiceByOrderId; // Assuming this is the Base64 encoded JSON
 

      if (base64Invoice) {
        try {
          // Decode Base64 to JSON
          const base64Prefix = "data:application/json;base64,";
          const base64Data = base64Invoice.startsWith(base64Prefix)
            ? base64Invoice.substring(base64Prefix.length)
            : base64Invoice;

          // Convert Base64 to JSON object
          const jsonData = base64ToJson(base64Data);

          // Render the EJS template
          const renderedHtml = renderEjsTemplate({ invoice: jsonData });

          // Create a new div to hold the rendered HTML content
          const pdfContent = document.createElement('div');
          pdfContent.innerHTML = renderedHtml;

          // Use html2pdf to convert the content to a PDF with custom settings
          const opt = {
            margin: [1, 0.9], // 0.5-inch top/bottom, 0.3-inch left/right to avoid right-side cutoff
            filename: `invoice_${jsonData._id}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 }, // Higher scale for better quality
            jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' } // A4 page format
          };

          // Generate the PDF and open it in a new tab
          html2pdf().from(pdfContent).set(opt).toPdf().get('pdf').then((pdf) => {
            const pdfBlob = pdf.output('blob');
            
            // Create a blob URL for the PDF
            const pdfUrl = URL.createObjectURL(pdfBlob);
            
            // Open the PDF in a new tab
            window.open(pdfUrl);
          });

        } catch (error) {
          console.error('Failed to decode Base64 or render EJS', error);
        }
      } else {
        console.error('Invoice data not found');
      }
    },
    onError: (error) => {
      console.error('Error fetching invoice', error);
    },
  });
};

  


  
  const getPhonePayRefundStatus = (statusNumber) => {
    const refundStatusMap = {
      0: "Not Applicable",
      1: "Refund Pending",
      2: "Refund Initiated",
      3: "Refund Success",
      4: "Refund Failed",
      5: "Refund Applicable"
    };

    return refundStatusMap[statusNumber] || "N/A";
  };
  const getOrderWorkerEmail = (orderState) => {
    switch (orderState.workerStatus) {
      case 1:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 2:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 3:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 4:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 5:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 6:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 7:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      case 8:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      case 9:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      default:
        return 'No Worker Assigned';
    }
  };
  const handleTimeSlotModal = ()=>{
    setTimeSlotModalModal(true)
  }
  return (
    <>
      <Header />
      <Container className={globalClasses.flex}>
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button

            className={golbalClasses.dashbordBtn}
            onClick={handlePrint}
          >
            <Icon icon="gridicons:print" style={{ color: "white" }} />  {t('Print')}
          </Button>
          <Button className={golbalClasses.dashbordBtn} onClick={handleBackClick}>
            <ArrowBackIosIcon onClick={handleBackClick} /> {t('Back')}
          </Button>
        </Box>
        <Box container className={classes.container}>
          <Box className={classes.flexRow}>
            <Box
              item
              className={
                classes.heading
              }>
              <Typography
                variant="h6"
                className={
                  classes.textWhite
                }>
                {t('Order Details')}
              </Typography>
            </Box>

          </Box>
          <Box className={classes.form} style={{ height: "663px" }}>
            <Grid container spacing={1} mt={1} className={classes.section}>
              <Grid container item xs={12} md={6}>
                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Order ID

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    {(data && data.getOrderDetailsById) ? data.getOrderDetailsById._id : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
  <Typography className={classes.typographyOrder}>
    Customer Name
  </Typography>
</Grid>
<Grid item xs={6} md={6}>
  <Typography className={classes.typographyOrder}>
    {(data && data.getOrderDetailsById && data.getOrderDetailsById.customer && data.getOrderDetailsById.customer.name) 
      ? data.getOrderDetailsById.customer.name 
      : "N/A"}
  </Typography>
</Grid>

                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Contact Number

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.headingText} style={{ display: 'inline-flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>
                    <Icon icon="fluent:phone-12-regular" width="20" height="20" style={{ color: " #66a1ff" }} />  {(data && data.getOrderDetailsById &&   data.getOrderDetailsById.customer) ? data.getOrderDetailsById.customer.phone : "N/A"}
                    {/* {data.getOrderDetailsById.customer.phone} */}
                  </Typography>
                </Grid>

                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Item Count

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>

                    {(data && data.getOrderDetailsById && data.getOrderDetailsById.items) ? data.getOrderDetailsById.items.length : "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={6} md={6}  >
                  <Typography className={classes.typographyOrder}  >
                    Order Amount

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>
                    <Icon icon="mdi:rupee" width="14" height="14" style={{ color: " black", }} />
                    {(data && data.getOrderDetailsById &&data.getOrderDetailsById.orderAmount) ? data.getOrderDetailsById.orderAmount : "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Order Date

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>

                    {(data && data.getOrderDetailsById && data.getOrderDetailsById.orderDate) ? formatDate(data.getOrderDetailsById.orderDate) : "N/A"}

                  </Typography>
                </Grid>

                <Grid item xs={6} md={6}  >
                  <Typography className={classes.typographyOrder}  >
                    Deliver Date

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    {(data && data.getOrderDetailsById && data.getOrderDetailsById.deliveryDate) ? data.getOrderDetailsById.deliveryDate : "N/A"}

                  </Typography>

                  {workerType===1? 
                  <Box>
                 <Button onClick={handleTimeSlotModal} className={globalClasses.dashbordBtn}>
                 Change Devlivery  Slot
                  </Button>
                  </Box>
                   : null}
                </Grid>
              
              </Grid>

              <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
                <Divider variant="middle"
                  sx={{
                    borderBottomWidth: 4, // Increase thickness

                  }} />
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder}>
                    Payment Information :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    Payment Method : {(data && data.getOrderDetailsById) ? getPaymentMethod(data.getOrderDetailsById.paymentMethod) : "N/A"}
                    <br />
                    Payment Status : {(data && data.getOrderDetailsById) ? getPaymentStatus(data.getOrderDetailsById.paymentStatus) : "N/A"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3} style={{ marginTop: "10px" }}>

                  <Typography className={classes.typographyOrder} >
                    Refund Status :
                  </Typography>

                </Grid>
                <Grid item xs={6} md={6} container alignItems="center">
                  <Typography className={classes.typographyOrder} style={{ marginRight: '8px' }}>
                    {data && data.getOrderDetailsById ? getPhonePayRefundStatus(data.getOrderDetailsById.refundStatus) : "N/A"}
                  </Typography>
                  {data && data.getOrderDetailsById && data.getOrderDetailsById.refundStatus === 4 ?
                    <Box>
                      <Button
                        className={golbalClasses.dashbordBtn}
                        onClick={handleRetryClick}
                      >
                        <Icon icon="pajamas:retry" style={{ color: "white", marginRight: "5px" }} /> {t('Retry')}
                      </Button>
                    </Box> : null}
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                    Wallet Info :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById.billDetails.walletInfo ? data.getOrderDetailsById.billDetails.walletInfo.amount != null || undefined ?data.getOrderDetailsById.billDetails.walletInfo.amount : 0 : "N/A"} ({data && data.getOrderDetailsById.billDetails.walletInfo ? data.getOrderDetailsById.billDetails.walletInfo.message !=null ?data.getOrderDetailsById.billDetails.walletInfo.message  : "N/A" : "N/A" })
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                  Nest Info :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById.billDetails.nestInfo ? data.getOrderDetailsById.billDetails.nestInfo.amount != null || undefined ? data.getOrderDetailsById.billDetails.nestInfo.amount: 0 : "N/A"}(   {data && data.getOrderDetailsById.billDetails.nestInfo ? data.getOrderDetailsById.billDetails.nestInfo.message != null ? data.getOrderDetailsById.billDetails.nestInfo.message:"N/A" : "N/A"}
                    )
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                  Coupon Info :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById.billDetails.couponInfo ? data.getOrderDetailsById.billDetails.couponInfo.amount != null || undefined ?data.getOrderDetailsById.billDetails.couponInfo.amount : 0 : "N/A"}(   {data && data.getOrderDetailsById.billDetails.couponInfo ? data.getOrderDetailsById.billDetails.couponInfo.message!= null ?  data.getOrderDetailsById.billDetails.couponInfo.message :"N/A" : "N/A"})
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                  Cancelled Info :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo ? data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo.amount : "N/A"} (                    {data && data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo ? data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo.message  != null ?  data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo.message : "N/A"  : "N/A"})
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
                <Divider variant="middle"
                  sx={{
                    borderBottomWidth: 4,
                  }} />
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                    Order Status :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById ? getOrderStatusWord(data.getOrderDetailsById.orderState.status) : "N/A"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                    Worker Status :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById ? getWorkerStatus(data.getOrderDetailsById.orderState.workerStatus) : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                    Worker Email :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById ? getOrderWorkerEmail(data.getOrderDetailsById.orderState) : "N/A"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
                <Divider variant="middle"
                  sx={{
                    borderBottomWidth: 4,
                  }} />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className={classes.typographyOrder} style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <Icon icon="mdi:address-marker-outline" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} />
                  Delivery Address
                </Typography>
                <Typography className={classes.typographyOrder}>
                  {data && data.getOrderDetailsById && data.getOrderDetailsById.deliveryAddress
                    ? `${data.getOrderDetailsById.deliveryAddress.details} ,${data.getOrderDetailsById.deliveryAddress.deliveryAddress}}`
                    : "N/A"
                  }
                </Typography>
              </Grid>
            </Grid>
          </Box>

        </Box>
        <DataTable
         subHeader={true}
                  subHeaderComponent={
                    ((data && data.getOrderDetailsById.orderState.workerStatus === 2)||(data && data.getOrderDetailsById.orderState.workerStatus === 5))?
                   <Button onClick={()=>{
                    toggleModal(filtered);
                    localStorage.setItem('orderId', orderDetails._id)
                   }}>Modify Order </Button>
                   :null
                  }
               

          title={<TableHeader title={t('Orders')} />}
          columns={columns}
          data={filtered}
          pagination
          progressPending={loadingQuery}
          progressComponent={<CustomLoader />}
          customStyles={customStyles}
        // selectableRows
        />
        <Grid container >
          <Grid item xs={6} md={6}>
          </Grid>
          <Grid item container spacing={1} xs={12} md={6} style={
            {
              background: "white", marginTop: "10px", borderRadius: 20, padding: "5px"
            }
          }>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "15px" }}>
                <Icon icon="marketeq:price-tag" width="24" height="24" style={{ marginRight: '8px' }} /> MRP
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                 <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.itemsMrp : 0}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="teenyicons:discount-outline" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} />Items Discount
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                - <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black" }} />
                {billDetails ? billDetails.itemSavings : 0}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="ri:price-tag-2-line" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} />Items Total price 
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                 <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black" }} />
            
                {billDetails ? billDetails.itemsTotal : 0}
              </Typography>
            </Grid>
            { billDetails && billDetails.couponInfo && billDetails.couponInfo.applied ?  
        
        <>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="streamline:discount-percent-coupon" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Coupon 
              </Typography>
            </Grid>
            
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                -<Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.couponInfo?billDetails.couponInfo.amount : 0 : 0}
              </Typography>
            </Grid>
            </>: null}
            { billDetails && billDetails.walletInfo && billDetails.walletInfo.applied ?  
        
        <>
        <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="streamline:discount-percent-coupon" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Wallet 
              </Typography>
            </Grid>
            
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                -<Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.walletInfo?billDetails.walletInfo.amount : 0 : 0}
              </Typography>
            </Grid>
            </>: null}
            { billDetails && billDetails.referralInfo && billDetails.referralInfo.applied ?  
        
        <>
        <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="streamline:discount-percent-coupon" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Referral
              </Typography>
            </Grid>
            
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                -<Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.referralInfo?billDetails.referralInfo.amount : 0 : 0}
              </Typography>
            </Grid>
            </>: null}
            { billDetails && billDetails.nestInfo && billDetails.nestInfo.applied ?  
        
        <>
        <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="streamline:discount-percent-coupon" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Nest 
              </Typography>
            </Grid>
            
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                -<Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.walletInfo?billDetails.nestInfo.amount : 0 : 0}
              </Typography>
            </Grid>
            </>: null}


           { (billDetails &&  billDetails.chargesWaived == 1)?
           <>
          
           <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="ri:e-bike-line" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Delivery Charges
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px"  }}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.deliveryCharge : 0}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="tdesign:undertake-transaction" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Handling Charges
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" ,textDecoration: "line-through",}}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.handlingCharge : 0}
              </Typography>
            </Grid>
            
            </>:
            (billDetails &&  billDetails.chargesWaived == 2)?
            <>
            
            <Grid item xs={6} md={6}>
                          <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                            <Icon icon="ri:e-bike-line" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Delivery Charges
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                          <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" ,textDecoration: "line-through", }}>
                            + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                            {billDetails ? billDetails.deliveryCharge : 0}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                            <Icon icon="tdesign:undertake-transaction" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Handling Charges
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                          <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                            + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                            {billDetails ? billDetails.handlingCharge : 0}
                          </Typography>
                        </Grid>
                       
                        </>
            : 
            (billDetails &&  billDetails.chargesWaived == 3)?

<>
<Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="ri:e-bike-line" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Delivery Charges
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" ,textDecoration: "line-through",}}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.deliveryCharge : 0}
              </Typography>
            </Grid>
<Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="tdesign:undertake-transaction" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Handling Charges
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px",textDecoration: "line-through", }}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.handlingCharge : 0}
              </Typography>
            </Grid>
</>
: 
<>
<Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="ri:e-bike-line" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Delivery Charges
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" ,}}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.deliveryCharge : 0}
              </Typography>
            </Grid>
<Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="tdesign:undertake-transaction" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Handling Charges
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px", }}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.handlingCharge : 0}
              </Typography>
            </Grid>
</>}



            <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
              <Divider variant="middle"
                sx={{
                  borderBottomWidth: 4,
                }} />

                
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                <Icon icon="teenyicons:discount-outline" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Total Savings 
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ fontWeight: 600, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                -<Icon icon="mdi:rupee" width="20" height="20" style={{ color: "black" }} />
                {billDetails ? billDetails.totalSaving : 0}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                <Icon icon="fluent:money-hand-16-regular" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Net Price
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ fontWeight: 600, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Icon icon="mdi:rupee" width="20" height="20" style={{ color: "black" }} />
                {billDetails ? billDetails.grandTotal : 0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          {(data && data.getOrderDetailsById.orderState.workerStatus === 1 && workerType === 0) || (data && data.getOrderDetailsById.orderState.workerStatus === 4 && workerType === 1) ? (
            <>


            <Button onClick={handleReadyButtonClickAccept} className={globalClasses.dashbordBtn}>
              Accept Order
            </Button>
            
          
           </>
          ) :
           (data && data.getOrderDetailsById.orderState.workerStatus === 2 && workerType === 0) ? (
            
            <div>
              {
                (data && data.getOrderDetailsById.isOrderModified === true )?
                <Button onClick={openVeiwDetailModal} className={globalClasses.dashbordBtn}>
               View Modified Details
              </Button>
 
              :null
 
             
              }

 <Button onClick={handleReadyButtonClick} className={globalClasses.dashbordBtn}>
              Packing Completed
            </Button>
            </div>
           
          ) 
          : (data && data.getOrderDetailsById.orderState.workerStatus === 5 && workerType === 1) ?
          <>
        {(data && data.getOrderDetailsById.isOrderModified === true && workerType === 1)?
               <Button onClick={openVeiwDetailModal} className={globalClasses.dashbordBtn}>
              View Modified Details
             </Button>

             :null

            }
            <Button onClick={handleReadyButtonClick} className={globalClasses.dashbordBtn}>
              Billing Completed
            </Button> 
            
            </>
            : (data && data.getOrderDetailsById.invoiceId && workerType === 1) ?
              <>
                <Button onClick={() => handleViewDetails()} className={globalClasses.dashbordBtn}>
                  View Invoice
                </Button>
                <Button disabled onClick={handleReadyButtonClick} className={globalClasses.dashbordBtnDisabled}>
                  Order Completed
                </Button>
              </> :
              (
                <Button disabled onClick={handleReadyButtonClick} className={globalClasses.dashbordBtnDisabled}>
                  Order Completed
                </Button>
              )}
        </Box>
        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <EditOrderByWorker vendor={vendors} editModal={editModal} onClose={closeEditModal} refetch={refetch} payMethod={payMethodVar} sellingPrice={sellingPrice} />
        </Modal>
        <Modal
          open={viewDetailsModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <ViewEditORderDetails vendor={vendors} editModal={viewDetailsModal} onClose={closeViewDetailModal} refetch={refetch} payMethod={payMethodVar} sellingPrice={sellingPrice}  customerType={customerType}/>
        </Modal>
        <Modal
          open={timeSlotModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            closeViewDetailModal()
          }}>
          <ChangesDeliverySlot handlereload = { handlereload}vendor={data && data.getOrderDetailsById} editModal={timeSlotModal} onClose={closeTimeSlotModal} refetch={refetch} payMethod={payMethodVar} sellingPrice={sellingPrice} />
        </Modal>
        <Box mt={2}>
          {success && (
            <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                {success}
              </Alert>
            </Snackbar>
          )}
          {error && (
            <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                {error}
              </Alert>
            </Snackbar>
          )}
        </Box>
      </Container>

    </>
  )
}

export default withTranslation()(PackageOrderDetails)



